
import { defineComponent, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import Modal from "@/components/UI/Modal";
import router from "@/router";

export default defineComponent({
  props: {
    isMenuHide: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hide = inject("handleMenuHide");
    const isHeaderHide = inject("isHeaderHide")
    const router = useRouter();
    const route = useRoute();
    function handleHide() {
      (hide as () => void)();
    }
    //  展示用户信息
    const userInfo = inject("userInfo") as any;

    //查看购物车
    function toViewCart() {

      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          content: '<div>登录后才可查看购物车</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            (hide as () => void)();
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        (hide as () => void)();
        router.push({name: "ShopCar"});
      }
    }


    function toLogin() {
      (hide as () => void)();
      if(route.path != "/login"){
        router.push({
          name: "Mine",
        })
      }else{
        router.push({
          name: "Mine",
        })
      }

    }

    function toSignin() {
        (hide as () => void)();
    }

    return {
      toViewCart,
      handleHide,
      toLogin,
      userInfo,
      toSignin
    };
  },
});
