import { createRouter, createWebHashHistory, RouteLocationNormalized, RouteRecordRaw, createWebHistory } from 'vue-router'
import mapRule from './config'
import navigatorType from '@/utils/browser'

const isPc = navigatorType()
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      name: 'title',
      keepAlive: true
    },
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('../pages/Index.vue'),
        meta: {
          hideBack: true,
          keepAlive: true,
          footActive: "first",
          // title:"国家标准物质网 国家标准物质中心 伟业计量",
          title: "伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/interest',
        name: 'Interest',
        component: () => import('../pages/Interest.vue'),
        meta: {
          footActive: "first",
          // title:"感兴趣的人-个人主页-伟业计量-国家标准物质网",
          title: "感兴趣的人-个人主页-伟业计量",
          content: {
            keywords: "个人中心,关注,伟业计量",
            description: "伟业计量个人中心是标准物质网用户在交流区的个人主页展示,可供用户查看帖子、课堂、圈子及其他版块内容，并包含用户的关注、粉丝、积分、获赞、勋章等详情.客服中心:4000-999-322",
          }
        },
      },
      {
        path: '/applySaleOrder',
        name: 'ApplySaleOrder',
        component: () => import('../pages/ApplySaleOrder.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          title: "我的售后-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/saleOrderDetail',
        name: 'SaleOrderDetail',
        component: () => import('../pages/SaleOrderDetail.vue'),
        meta: {
          // title:"订单详情页-伟业计量-国家标准物质中心",
          title: "售后详情页-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../pages/News.vue'),
        meta: {
          keepAlive: true,
          searchType: 'news',
          footActive: "first",
          // title:"公司动态-伟业计量-国家标准物质网",
          title: "新闻中心-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "公司动态-新闻中心.涵盖食品检测,环境监测,临床化学与药物分析,冶金与地质等领域标准物质最新动态,前沿科技和文献资料.专业,权威,及时,全面."
          }
        },
      },
      {
        path: '/newsdetail',
        name: 'NewsDetail',
        component: () => import('../pages/NewsDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'news',
          // title:"新闻详情页-伟业计量-国家标准物质网",
          title: "新闻标题-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "公司动态-新闻中心.涵盖食品检测,环境监测,临床化学与药物分析,冶金与地质等领域标准物质最新动态,前沿科技和文献资料.专业,权威,及时,全面."
          }
        },
      },
      {
        path: '/previewdetail',
        name: 'previewDetail',
        component: () => import('../pages/previewDetail.vue'),
        meta: {
          hideFooter: true,
          // searchType: 'news',
          // title:"新闻详情页-伟业计量-国家标准物质网",
          title: "新闻标题-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "公司动态-新闻中心.涵盖食品检测,环境监测,临床化学与药物分析,冶金与地质等领域标准物质最新动态,前沿科技和文献资料.专业,权威,及时,全面."
          }
        },
      },
      {
        path: '/docDetail',
        name: 'DocDetail',
        component: () => import('../pages/DocDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'topic',
          // title:"文库详情页-伟业计量-国家标准物质网",
          title: "文库标题-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/forget',
        name: 'Forget',
        component: () => import('../pages/Forget.vue'),
        meta: {
          // title:"密码更换-伟业计量-国家标准物质网",
          title: "密码更换-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },

      {
        path: '/about',
        name: 'About',
        component: () => import('../pages/About.vue'),
        meta: {
          footActive: "first",
          // title:"关于伟业计量 国家标准物质网 伟业计量国家标准物质研究中心",
          title: "关于我们-伟业计量",
          content: {
            keywords: "标准物质,国家标准物质网,国家标准物质中心,标准物质网,国家标准物质研究中心,伟业计量",
            description: "伟业计量是一家集标准物质研发,生产和销售为一体的高科技企业,2017年建立24000平科技园并引进ISO9001质量管理体系,至2019年10月获批国家标准物质300余种,同时提供产品定制服务,全方位满足您的需求。诚招代理,客服中心:4000-999-322",
          }
        },
      },
      {
        path: '/bbs',
        name: 'Topic',
        component: () => import('../pages/Topic.vue'),
        meta: {
          searchType: 'topic',
          footActive: "first",
          // title:"发现动态-国家标准物质网",
          title: "发现动态-伟业计量",
          content: {
            keywords: "关注,课堂,圈子,标准物质,标准物质网",
            description: "国内权威的计量领域综合交流平台,汇集最新标物帖子、关注人的动态、伟业课堂及计量行业圈子动态,让您足不出户,一览计量行业相关信息,客服中心:400-999-3855",
          }
        }
      },
      {
        path: '/bbsdetail',
        name: 'TopicDetail',
        component: () => import('../pages/TopicDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'topic',
          // title:"帖子详情页-伟业计量-国家标准物质网",
          title: "帖子标题-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/postTopic',
        name: 'PostTopic',
        component: () => import('../pages/PostTopic.vue'),
        meta: {
          hideFooter: true,
          searchType: 'topic',
          requireAuth: true,
          // title:"发现动态-国家标准物质网",
          title: "发现动态-伟业计量",
          content: {
            keywords: "关注,课堂,圈子,标准物质,标准物质网",
            description: "国内权威的计量领域综合交流平台,汇集最新标物帖子、关注人的动态、伟业课堂及计量行业圈子动态,让您足不出户,一览计量行业相关信息,客服中心:400-999-3855",
          }
        },
      },
      {
        path: '/productCategory',
        name: 'ProductCategory',
        meta: {
          footActive: "second",
          title: "标准物质目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/ProductCategory.vue'),
      },
      {
        path: '/product',
        name: 'product',
        meta: {
          name: 'product',
          keepAlive: true,
          searchType: 'product',
          footActive: "second",
          title: "标准物质目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/Product.vue'),
      },
      {
        path: '/specialProCategory',
        name: 'specialProCategory',
        meta: {
          // footActive: "second",
          title: "专项标物",
          content: {
            keywords: "专项标物,5750专项标准物质,动物性食品中药物残留,GB 31658专项标准物质,2024全国食品安全抽检,标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/SpecialProCategory.vue'),
      },
      {
        path: '/specialProduct',
        name: 'specialProduct',
        meta: {
          name: 'specialProduct',
          keepAlive: true,
          searchType: 'specialProduct',
          // footActive: "second",
          title: "专项标物",
          content: {
            keywords: "专项标物,5750专项标准物质,动物性食品中药物残留,GB 31658专项标准物质,2024全国食品安全抽检,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/SpecialProduct.vue'),
      },
      {
        path: '/GbProduct',
        name: 'GB31658Product',
        meta: {
          name: 'GB31658Product',
          keepAlive: true,
          searchType: 'GB31658Product',
          title: "动物性食品中药物残留,GB 31658专项标准物质",
          content: {
            keywords: "动物性食品中药物残留,GB 31658专项标准物质,标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/GB31658Product.vue'),
      },
      {
        path: '/bnccCategory',
        name: 'BnccCategory',
        meta: {
          // footActive: "second",
          title: "标准物质目录-菌种&细胞分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/BnccCategory.vue'),
      },
      {
        path: '/bnccProduct',
        name: 'bnccProduct',
        meta: {
          name: 'bnccProduct',
          keepAlive: true,
          searchType: 'bnccProduct',
          // footActive: "second",
          title: "标准物质目录-菌种&细胞分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/BnccProduct.vue'),
      },
      {
        path: '/bncc',
        name: 'Bncc',
        meta: {
          name: 'bncc',
          keepAlive: true,
          searchType: 'bncc',
          footActive: "second",
          title: "细胞菌种目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/Bncc.vue'),
      },
      {
        path: '/:id',
        name: 'productdetail',
        component: () => import('../pages/ProductDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'product',
          // title:"产品详情页-伟业计量-国家标准物质网",
          title: "产品中文名-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/:id/:classId',
        name: 'bnccdetail',
        component: () => import('../pages/BnccDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/strAppraisal',
        name: 'strAppraisal',
        component: () => import('../pages/StrAppraisal.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/mycoplasma',
        name: 'mycoplasma',
        component: () => import('../pages/Mycoplasma.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/dnaExtraction',
        name: 'dnaExtraction',
        component: () => import('../pages/DnaExtraction.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
  // 菌种技术服务页面
      {
        path: '/b/identiFication',
        name: 'identiFication',
        component: () => import('../pages/identiFication.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种&细胞-菌种技术服务-菌种鉴定",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/preservation',
        name: 'preservation',
        component: () => import('../pages/preservation.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种&细胞-菌种技术服务-菌种代保藏",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/subculTure',
        name: 'subculTure',
        component: () => import('../pages/subculTure.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种&细胞-菌种技术服务-菌种代培养",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },

      {
        path: '/b/detection',
        name: 'detection',
        component: () => import('../pages/detection.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种&细胞-菌种技术服务-菌种检测",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/b/digitalpcr',
        name: 'digitalpcr',
        component: () => import('../pages/digitalpcr.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种&细胞-菌种技术服务-数字PCR定值服务",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/standard',
        name: 'standard',
        meta: {
          name: 'standard',
          keepAlive: true,
          searchType: 'standard',
          footActive: "second",
          title: "标准目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,伟业计量",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/Standard.vue')
      },
      {
        path: '/customize',
        name: 'customize',
        meta: {
          name: 'customize',
          hideFooter: true,
          searchType: 'product',
          // title:"定制产品-标准物质-国家标准物质网",
          title: "定制产品-伟业计量",
          content: {
            keywords: "定制服务,按需定制,标准物质,单标,混标,国家标准物质",
            description: "伟业计量拥有国家标准物质定级证书,并获得质检总局授权,是国家标准物质指定生产单位,可提供各种不同浓度的单标及混标定制服务,也可按需定制.专业权威,品质保证,服务一流,客服中心:400-999-3855",
          }
        },
        component: () => import('../pages/Customize.vue')
      },
      {
        path: '/s/:id/:classId',
        name: 'standarddetail',
        component: () => import('../pages/StandardDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'standard',
          // title:"标准详情页-伟业计量-国家标准物质网",
          title: "标准详情页-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('../pages/Order.vue'),
        meta: {
          footActive: "third",
          // title:"订单管理-伟业计量-国家标准物质网",
          title: "订单管理-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/invoice',
        name: 'Invoice',
        component: () => import('../pages/Invoice.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的发票-伟业计量-国家标准物质中心",
          title: "我的发票-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/intergral',
        name: 'Intergral',
        component: () => import('../pages/Intergral.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"积分兑换-伟业计量-国家标准物质中心",
          title: "积分兑换-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/exchange',
        name: 'Exchange',
        component: () => import('../pages/Exchange.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          // title:"积分兑换-伟业计量-国家标准物质中心",
          title: "积分兑换-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/exchangeDetail',
        name: 'ExchangeDetail',
        component: () => import('../pages/ExchangeDetail.vue'),
        meta: {
          requireAuth: true,
          // title:"积分兑换-伟业计量-国家标准物质中心",
          title: "积分兑换-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },


      {
        path: '/intergralRule',
        name: 'IntergralRule',
        component: () => import('../pages/IntergralRule.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"积分规则-伟业计量-国家标准物质中心",
          title: "积分规则-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },

      {
        path: '/bill',
        name: 'Bill',
        component: () => import('../pages/Bill.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的贷款-伟业计量-国家标准物质中心",
          title: "我的贷款-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/user',
        name: 'UserInfo',
        component: () => import('../pages/UserInfo.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          // title:"会员中心-伟业计量-国家标准物质中心",
          title: "会员中心-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/userEdit',
        name: 'UserInfoEdit',
        component: () => import('../pages/UserInfoEdit.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          // title:"会员中心-伟业计量-国家标准物质中心",
          title: "会员中心-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/foot',
        name: 'FootPrint',
        component: () => import('../pages/FootPrint.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的足迹-伟业计量-国家标准物质中心",
          title: "我的足迹-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/message',
        name: 'Message',
        component: () => import('../pages/Message.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的消息-伟业计量-国家标准物质中心",
          title: "我的消息-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/attention',
        name: 'Attention',
        component: () => import('../pages/Attention.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的关注-伟业计量-国家标准物质中心",
          title: "我的关注-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/unsubscribe',
        name: 'Unsubscribe',
        component: () => import('../pages/Unsubscribe.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"关注用户-伟业计量-国家标准物质中心",
          title: "关注用户-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/collection',
        name: 'Collection',
        component: () => import('../pages/Collection.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的收藏-伟业计量-国家标准物质中心",
          title: "我的收藏-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('../pages/Account.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的账户-伟业计量-国家标准物质中心",
          title: "我的账户-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/changeMobile',
        name: 'ChangeMobile',
        component: () => import('../pages/ChangeMobile.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"修改手机号-伟业计量-国家标准物质中心",
          title: "修改手机号-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/changePwd',
        name: 'ChangePwd',
        component: () => import('../pages/ChangePwd.vue'),
        meta: {
          footActive: "third",
          hideFooter: true,
          // title:"修改密码-伟业计量-国家标准物质中心",
          title: "修改密码-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/prize',
        name: 'Prize',
        component: () => import('../pages/Prize.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的奖项-伟业计量-国家标准物质中心",
          title: "我的奖项-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/suggest',
        name: 'Suggest',
        component: () => import('../pages/Suggest.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的建议-伟业计量-国家标准物质中心",
          title: "我的建议-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/shopCar',
        name: 'ShopCar',
        component: () => import('../pages/ShopCar.vue'),
        meta: {
          // footActive: "third",
          // title:"购物车-伟业计量-国家标准物质中心",
          title: "购物车-伟业计量",
          footActive: "four",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/coupon',
        name: 'Coupon',
        component: () => import('../pages/Coupon.vue'),
        meta: {
          title: "优惠券-伟业计量",
          footActive: "third",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/myInvitation',
        name: 'myInvitation',
        component: () => import('../pages/myInvitation.vue'),
        meta: {
          title: "我的邀请-伟业计量",
          footActive: "third",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/Introduction',
        name: 'Introduction',
        component: () => import('../pages/Introduction.vue'),
        meta: {
          title: "我的邀请-伟业计量",
          footActive: "third",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/Invitation',
        name: 'Invitation',
        component: () => import('../pages/Invitation.vue'),
        meta: {
          title: "邀请注册-伟业计量",
          footActive: "first",
          hideFooter: true,
          hideBack:true,
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/submitOrder',
        name: 'SubmitOrder',
        component: () => import('../pages/SubmitOrder.vue'),
        meta: {
          Authentication: true,  // 需要登录认证
          hideFooter: true,
          // title:"提交订单-伟业计量-国家标准物质中心",
          title: "提交订单-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/updateAddressOfOrder',
        name: 'updateAddressOfOrder',
        component: () => import('../pages/UpdateAddressOfNeiOrder.vue'),
        meta: {
          Authentication: true,  // 需要登录认证
          hideFooter: true,
          title: "地址修改-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },

      {
        path: '/openSearch',
        name: 'OpenSearch',
        component: () => import('../pages/OpenSearch.vue'),
        meta: {
          footActive: "third",
          // title:"搜索-伟业计量-国家标准物质中心",
          title: "搜索-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/personal',
        name: 'Personal',
        component: () => import('../pages/Personal.vue'),
        meta: {
          footActive: "third",
          // title:"个人主页-伟业计量-国家标准物质中心",
          title: "个人主页-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/signIn',
        name: 'signIn',
        component: () => import('../pages/SignIn.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"签到界面-伟业计量-国家标准物质中心",
          title: "签到界面-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/course',
        name: 'Course',
        component: () => import('../pages/Course.vue'),
        meta: {
          searchType: 'course',
          keepAlive: true,
          // footActive: "four",
          footActive: "third",
          // title:"培训中心-伟业计量-国家标准物质中心",
          title: "直播间-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/courseCommentDetail',
        name: 'CourseCommentDetail',
        component: () => import('../pages/CourseCommentDetail.vue'),
        meta: {
          searchType: 'course',
          hideFooter: true,
          // title:"课堂评论详情页-伟业计量-国家标准物质中心",
          title: "课堂评论详情页-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/topicCommentDetail',
        name: 'TopicCommentDetail',
        component: () => import('../pages/TopicCommentDetail.vue'),
        meta: {
          searchType: 'topic',
          hideFooter: true,
          // title:"帖子评论详情页-伟业计量-国家标准物质中心",
          title: "帖子评论详情页-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        },
      },
      {
        path: '/videodetail',
        name: 'videodetail',
        component: () => import('../pages/VideoDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          // title:"课堂详情页-伟业计量-国家标准物质网",
          title: "课堂详情页-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/livedetail',
        name: 'livedetail',
        component: () => import('../pages/LiveDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          // title:"直播详情页-伟业计量-国家标准物质网",
          title: "直播详情页-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/videoRealtime',
        name: 'videoRealtime',
        component: () => import('../pages/VideoRealtime.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          // title:"直播详情页-伟业计量-国家标准物质网",
          title: "直播详情页-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/videoRealtimeBncc',
        name: 'videoRealtimeBncc',
        component: () => import('../pages/VideoRealtimeBncc.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          // title:"直播详情页-伟业计量-国家标准物质网",
          title: "直播详情页-伟业计量",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        //nei
        path: '/addressOfUser',
        name: 'AddressOfUser',
        component: () => import('../pages/AddressOfNei.vue'),
        meta: {
          hideFooter: true,
          Authentication: true,
          footActive: "third",
          requireAuth: true,
          // title:"我的地址-伟业计量-国家标准物质中心",
          title: "我的地址-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        //nei
        path: '/addAddressOfUser',
        name: 'addAddressOfUser',
        component: () => import('../pages/AddAddressOfNei.vue'),
        meta: {
          hideFooter: true,
          footActive: "third",
          requireAuth: true,
          // title:"添加地址-伟业计量-国家标准物质中心",
          title: "添加地址-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/address',
        name: 'Address',
        component: () => import('../pages/Address.vue'),
        meta: {
          hideFooter: true,
          Authentication: true,
          footActive: "third",
          requireAuth: true,
          // title:"我的地址-伟业计量-国家标准物质中心",
          title: "我的地址-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/addAddress',
        name: 'addAddress',
        component: () => import('../pages/AddAddress.vue'),
        meta: {
          hideFooter: true,
          footActive: "third",
          requireAuth: true,
          // title:"添加地址-伟业计量-国家标准物质中心",
          title: "添加地址-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('../pages/Chat.vue'),
        meta: {
          hideFooter: true,
          // title:"私信界面-伟业计量-国家标准物质中心",
          title: "私信界面-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/orderDetail',
        name: 'OrderDetail',
        component: () => import('../pages/OrderDetail.vue'),
        meta: {
          // title:"订单详情页-伟业计量-国家标准物质中心",
          title: "订单详情页-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/neiOrderDetail',
        name: 'NeiOrderDetail',
        component: () => import('../pages/NeiOrderDetail.vue'),
        meta: {
          // title:"订单详情页-伟业计量-国家标准物质中心",
          title: "订单详情页-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login.vue'),
        meta: {
          hideFooter: true,
          // title:"登录页面-伟业计量-国家标准物质中心",
          title: "登录页面-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/initTel',
        name: 'InitTel',
        component: () => import('../pages/InitTel.vue'),
        meta: {
          hideFooter: true,
          // title:"绑定手机号-伟业计量-国家标准物质中心",
          title: "绑定手机号-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/setPassword',
        name: 'SetPassword',
        component: () => import('../pages/SetPassword.vue'),
        meta: {
          hideFooter: true,
          title: "设置密码-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/applyInvoice',
        name: 'ApplyInvoice',
        component: () => import('../pages/ApplyInvoice.vue'),
        meta: {
          hideFooter: true,
          // title:"申请发票-伟业计量-国家标准物质中心",
          title: "申请发票-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/exhibitionLottery',
        name: 'exhibitionLottery',
        component: () => import('../operation/ExhibitionLottery.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量展会大抽奖-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/exhibitionTurntable',
        name: 'exhibitionTurntable',
        component: () => import('../operation/ExhibitionTurntable.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量展会大抽奖-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/exhibitionCollectInfo',
        name: 'exhibitionCollectInfo',
        component: () => import('../operation/ExhibitionCollectInfo.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量展会",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/lottery',
        name: 'Lottery',
        component: () => import('../pages/Lottery.vue'),
        meta: {
          hideFooter: true,
          // title:"大抽奖-伟业计量-国家标准物质中心",
          title: "大抽奖-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/wechatred',
        name: 'WechatRed',
        component: () => import('../pages/WechatRed.vue'),
        meta: {
          hideFooter: true,
          title: "手机下单抽红包-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/dragonBoat2023',
        name: 'dragonBoat2023',
        component: () => import('../operation/dragonBoat2023.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2023端午享好礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/sendGiftOflLuxury',
        name: 'sendGiftOflLuxury',
        component: () => import('../operation/sendGiftOflLuxury2023.vue'),
        meta: {
          hideFooter: true,
          title: "豪派好礼——幸运大抽奖-标准物质网",
          content: {
            keywords: "伟业计量,每天豪派超多好礼，iPhone14等你拿，活动，标准物质，标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/productCertificate',
        name: 'productCertificate',
        component: () => import('../operation/productCertificate.vue'),
        meta: {
          hideFooter: true,
          title: "标准查询-标准物质网",
          content: {
            keywords: "伟业计量,每天豪派超多好礼，iPhone14等你拿，活动，标准物质，标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/InviteRegister',
        name: 'InviteRegister',
        component: () => import('../pages/InviteRegister.vue'),
        meta: {
          title: "邀请注册-伟业计量",
          footActive: "first",
          hideFooter: true,
          hideBack:true,
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/workStart',
        name: 'workStart',
        component: () => import('../operation/workStart.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2023开工有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/workStart2024',
        name: 'workStart2024',
        component: () => import('../operation/workStart2024.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2024开工有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/midAutumn2024',
        name: 'midAutumn2024',
        component: () => import('../operation/midAutumn2024.vue'),
        meta: {
          hideFooter: true,
          title: "开学迎中秋，答题赢好礼——幸运大抽奖|伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/dragonBoat2024',
        name: 'dragonBoat2024',
        component: () => import('../operation/dragonBoat2024.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2024端午享好礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },

      {
        path: '/startJob',
        name: 'StartJob',
        component: () => import('../operation/StartJob.vue'),
        meta: {
          hideFooter: true,
          // title:"试抽-伟业计量2021开工有礼-伟业计量-国家标准物质中心",
          title: "试抽-伟业计量2021开工有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/startJobReal',
        name: 'StartJobReal',
        component: () => import('../operation/StartJobReal.vue'),
        meta: {
          hideFooter: true,
          // title:"伟业计量2021开工有礼-伟业计量-国家标准物质中心",
          title: "伟业计量2021开工有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/startGift',
        name: 'StartGift',
        component: () => import('../operation/StartGift.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2022开工有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/lotteryTest',
        name: 'LotteryTest',
        component: () => import('../lottery/Lottery.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2021端午有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/LotteryDetail',
        name: 'lotteryDetail',
        component: () => import('../lottery/LotteryDetail.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2021端午有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/dragonBoatPage',
        name: 'DragonBoatPage',
        component: () => import('../operation/DragonBoatPage.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2022粽情端午-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/midAutumn',
        name: 'MidAutumn',
        component: () => import('../mid/MidAutumn.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2022中秋有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/anniversary',
        name: 'Anniversary2022',
        component: () => import('../operation/Anniversary2022.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量15周年庆-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/midTest',
        name: 'MidTest',
        component: () => import('../mid/Mid.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2021中秋有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/midDetail',
        name: 'MidDetail',
        component: () => import('../mid/MidDetail.vue'),
        meta: {
          hideFooter: true,
          title: "伟业计量2021中秋有礼-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/vote',
        name: 'VoteTest',
        component: () => import('../vote/Vote.vue'),
        meta: {
          hideFooter: true,
          title: "餐厅投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/voteView',
        name: 'VoteView',
        component: () => import('../vote/VoteView.vue'),
        meta: {
          hideFooter: true,
          title: "餐厅投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/staffVote',
        name: 'yearVote',
        component: () => import('../yearVote/yearVote.vue'),
        meta: {
          hideFooter: true,
          title: "员工投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/staffVoteView',
        name: 'yearVoteView',
        component: () => import('../yearVote/yearVoteView.vue'),
        meta: {
          hideFooter: true,
          title: "员工投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/leadVote',
        name: 'leadVote',
        component: () => import('../yearLeadVote/leadVote.vue'),
        meta: {
          hideFooter: true,
          title: "领导投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/leadVoteView',
        name: 'leadVoteView',
        component: () => import('../yearLeadVote/leadVoteView.vue'),
        meta: {
          hideFooter: true,
          title: "领导投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/realVote',
        name: 'RealVote',
        component: () => import('../yrealVote/RealVote.vue'),
        meta: {
          hideFooter: true,
          title: "员工投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/realVoteView',
        name: 'RealVoteView',
        component: () => import('../yrealVote/RealVoteView.vue'),
        meta: {
          hideFooter: true,
          title: "员工投票",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/videored',
        name: 'VideoRed',
        component: () => import('../pages/VideoRed.vue'),
        meta: {
          hideFooter: true,
          title: "直播红包-伟业计量",
          content: {
            keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },

      {
        path: '/CourseWX',
        name: 'CourseWX',
        component: () => import('../pages/CourseWX.vue'),
        meta: {
          hideFooter: true,
        }
      },
      // ===================================================================
    ]
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/Mine.vue'),
    meta: {
      Authentication: true,  // 需要登录认证
      footActive: "third",
      requireAuth: true,
      // title:"会员中心-伟业计量-国家标准物质中心",
      title: "会员中心-伟业计量",
      content: {
        keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
        description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
      }
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      footActive: "first",
      // title:"搜索界面-伟业计量-国家标准物质中心",
      title: "搜索界面-伟业计量",
      content: {
        keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
        description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
      }
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      footActive: "first",
      title: "404界面-伟业计量-国家标准物质中心",
      content: {
        keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
        description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
      }
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../testPage/Index.vue'),
    meta: {
      footActive: "first",
      title: "测试页面-伟业计量-国家标准物质中心",
    }
  },
  {
    path: '/testKd',
    name: 'TestKd',
    component: () => import('../testPage/TestKd.vue'),
    meta: {
      footActive: "first",
      title: "测试页面-伟业计量-国家标准物质中心",
    }
  },
  {
    path: '/maintain',
    name: 'Maintain',
    component: () => import('../maintenance/Maintain.vue'),
    meta: {
      footActive: "first",
      title: "系统维护中",
    }
  },

//    抢红包
  {
    path: '/redpacket',
    name: 'Redpacket',
    component: () => import('../pages/Redpacket.vue'),
    meta: {
      hideFooter: true,
      // title:"大抽奖-伟业计量-国家标准物质中心",
      title: "抢红包-伟业计量",
      content: {
        keywords: "伟业计量,标准物质,国家标准物质中心,国家标准物质网,标准物质网,标准品,对照品,标准查询,试剂,耗材,标准图书,产品订制",
        description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制13大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
      }
    }
  },
]

function scrollBehavior(to: any, from: any, savedPosition: any) {
  if (savedPosition) {
    return savedPosition
  } else {
    return {
      x: 0,
      y: 0
    }
  }
}


const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior
})

//  映射路由到pc端
function routerMap(to: RouteLocationNormalized) {
  const name = to.name;
  let path = mapRule[(name as string)];
  const idReg = /\{\{(.*?)\}\}/;
  if (isPc && path) {
    while (idReg.test(path)) {
      const argName = RegExp.$1;
      const id = to.query[argName] || to.params[argName]
      path = path.replace(idReg, id)
    }
    window.location.href = path
  }
}

router.beforeEach((to, from, next) => {
  routerMap(to)
  const href = window.location.href;
  const codeReg = /code=(.*?)&/;
  let code
  if( codeReg.test(href) ){
    code = RegExp.$1
  }
  const token = localStorage.getItem('token') || '';
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head');
    const meta = document.createElement('meta');
    const keywords = document.querySelector('meta[name="keywords"]');
    const description = document.querySelector('meta[name="description"]')
    keywords && keywords.setAttribute('content', to.meta.content.keywords)
    description && description.setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  const urlReg = /\/#(.*)/ig;
  if (href.match(urlReg)) {
    const urlPath = RegExp.$1;
    window.location.href = urlPath;
    return false
  }
  if (to.path === '/login') {
    if (token && to.query && to.query.redirect) {
      return next(to.query.redirect as string)
    } else {
      return next()
    }
  } else {
    if (to.matched.length === 0) {
      next("/error")
    }
  }

  if (!to.meta.requireAuth) {
    next()
  } else {
    if (token) {
      if (to.matched.length === 0) {
        next("/error")
      }
      next()
    } else {
      if (to.matched.length === 0) {
        next("/error")
      } else if (code) {
        next()
      } else {
        next({
          path: "login",
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  }
})





export default router
